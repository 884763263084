<template>
	<div class="flex flex-col">
		<div>
			<svg-icon name="mail" class="fill-current w-5 h-5 mr-1"/>
			<a :href="`mailto:${emailAddress}`">{{ emailAddress }}</a>
		</div>
		<div>
			<svg-icon name="telephone-filled" class="fill-current w-5 h-5 mr-1"/>
			<a href="tel:62435300">62 43 53 00</a>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		emailAddress: {
			type: String,
			default: 'org@glommen-mjosen.no'
		}
	}
}
</script>
